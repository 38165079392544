import { Component, Input } from '@angular/core';

@Component({
	selector: 'spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.scss'],
	standalone: false,
})
export class SpinnerComponent {
	@Input() size: number = 30;
	@Input() overlay: boolean = false;
}
