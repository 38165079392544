import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { CustomErrorMessage, CustomErrors, ErrorModel, OffsetCollection, OffsetPagination } from '@shared/models';
import { IProductMediaFilter, IProductMediaModel } from '@shared/models/products';
import { HttpUtils } from '@shared/util/http-utils';
import { catchError, map, Observable, of } from 'rxjs';
import { ValidatorErrorHandlerService } from '@core/services';

@Injectable({
	providedIn: 'root',
})
export class ProductMediaService {
	private _portalUrl: string;
	private _httpUtils: HttpUtils = new HttpUtils();

	constructor(
		private http: HttpClient,
		private validatorErrorHandlerService: ValidatorErrorHandlerService,
	) {
		this._portalUrl = environment.portalBaseAddress;
	}

	public getMany(
		filter: IProductMediaFilter,
		pager: OffsetPagination,
	): Observable<OffsetCollection<IProductMediaModel>> {
		const params: HttpParams = this._httpUtils.getParams({
			...pager,
			...filter,
		});
		return this.http
			.get<
				IProductMediaModel[]
			>(`${this._portalUrl}/api/v1/media/products/images`, { params: params, observe: 'response' })
			.pipe(
				map(resp => {
					return this._httpUtils.toOffsetCollection<IProductMediaModel>(resp);
				}),
			);
	}

	public getDetails(productId: number): Observable<IProductMediaModel | undefined> {
		const url = this._portalUrl + '/api/v1/media/products/' + productId + '/images';
		return this.http.get<IProductMediaModel>(url).pipe(
			this.validatorErrorHandlerService.suppressErrors([CustomErrors.ProductMediaNotFound]),
			this.validatorErrorHandlerService.catchError(),
		);
	}

	public GetDefaultThumbnail(productId: number): string{
		const url= this._portalUrl + `/api/v1/media/products/` + productId + `/images/default/thumbnail`;
		return url;
	}
}
