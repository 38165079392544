import { environment } from '@environments/environment';

export class PrinterImageUrlHelper {
	private static printerModelToImageMap: { [key: string]: string } = {
		CP302: 'Cw-CP30.png',
		ZD500R: 'ZD500R.png',
		ZD621R: 'ZD621R.png',
		ZT410R: 'ZT410R.png',
		ZT411R: 'ZT411R.png',
		R110XI4: 'R110xi4.png',
		T820: 'Pt-T800.png',
		T830: 'Pt-T800.png',
		T4204: 'Pt-T4000.png',
		T4304: 'Pt-T4000.png',
		T6204: 'Pt-T6000.png',
		T6304: 'Pt-T6000.png',
		T6604: 'Pt-T6000.png',
		T6206: 'Pt-T6000.png',
		T6306: 'Pt-T6000.png',
	};

	public static getImageUrl(name: string): string | undefined {
		for (const key in this.printerModelToImageMap) {
			if (name.startsWith(key)) {
				return `${environment.printerImageBaseUrl}${this.printerModelToImageMap[key]}`;
			}
		}

		return `${environment.printerImageBaseUrl}no_printer.png`;
	}
}
