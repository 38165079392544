<div
	class="skeleton"
	[ngClass]="{
		'without-left-border': !leftBorder,
	}"
	[ngStyle]="{
		minWidth: minWidth + 'px',
		maxWidth: maxWidth + 'px',
		height: height + 'px',
	}"
></div>
