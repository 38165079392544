import { Component, Input } from '@angular/core';
import { BaseCustomerSelectorComponent } from '@core/abstractions';
import { CustomerLightModel } from '@shared/models';
import { MetaDataService } from '@shared/services';
import { FilterService } from 'primeng/api';
import { Observable } from 'rxjs';
import { BlockPosition } from '@shared/enums';

@Component({
	selector: 'customer-selector',
	templateUrl: './customer-selector.component.html',
	styleUrls: ['./customer-selector.component.scss'],
	standalone: false,
})
export class CustomerSelectorComponent extends BaseCustomerSelectorComponent {
	@Input() error?: string;

	constructor(
		filterService: FilterService,
		private metaDataService: MetaDataService,
	) {
		super(filterService);
	}

	public override getCustomer(): Observable<CustomerLightModel[]> {
		return this.metaDataService.getCustomers();
	}

	protected readonly BlockPosition = BlockPosition;

	onModelChange(event: any) {
		if (event) {
			this.onCustomerSelect(event);
		} else {
			this.onClear();
		}
	}
}
