<div class="upload-pop-up-container">
	<section class="upload-section">
		<div class="get-template-wrapper">
			<app-button
				gaEvent="Get template (Import xlsx)"
				[variant]="buttonVariantEnum.Light"
				text="Get Template"
				(click)="downloadTemplate()"
			></app-button>
			<p class="text-info-description">Please, use our template for Orders uploading.</p>
		</div>
		<div class="upload-input-box">
			<p-fileupload
				[customUpload]="true"
				excelReader
				(dataChanged)="onExcelSelected($event)"
				[multiple]="false"
				(onClear)="onClear()"
				accept=".xlsx, .csv"
				[auto]="true"
				[showCancelButton]="true"
				invalidFileTypeMessageSummary="Invalid file type"
				invalidFileTypeMessageDetail="Allowed file types: .xlsx, .csv"
				#fileUploader
			>
				<ng-template let-file pTemplate="file">
					<div class="file-name-wrapper">
						<div class="file-name">{{ file.name }}</div>

						<i class="mdi mdi-close" (click)="fileUploader.clear()"></i>
					</div>
				</ng-template>
				<ng-template pTemplate="content" let-files>
					@if (files.length == 0) {
						<div class="unselectable">Drop the template (.xlsx, .csv) here!</div>
					}
				</ng-template>
			</p-fileupload>
		</div>
		<!-- TODO Show one error message with titles listed  -->
		<div class="error-box">
			@for (error of excelErrors; track error) {
				<p-message severity="error" [text]="error"></p-message>
			}
		</div>
		<div class="show-btn-box">
			<span class="action-link" (click)="showMapping()">
				The Custom Template details
				<i class="mdi mdi-chevron-double-right" [ngClass]="{ 'rotate-90': isMappingVisible }"></i
				></span>

			<p>
				Upload your file and match the column names. <br/>
				Please type your Spreadsheet column headers or select them in dropdowns if necessary. <br/>
				The custom template for Spreadsheet mapping will be saved automatically.
			</p>
		</div>
	</section>

	<section [ngClass]="{ 'block-visible': isMappingVisible }" class="mapping-section">
		<div class="match-switcher">
			<div class="control-input-checkbox">
				<p-radioButton
					gaModelChangeEvent="Match by 'title' (Product Exel Reader)"
					inputId="byTitle"
					name="matchType"
					[(ngModel)]="hasColumnTitles"
					[value]="true"
					(ngModelChange)="updateTable()"
				></p-radioButton>
				<label for="byTitle">Match by <b>Title</b></label>
			</div>
			<div class="control-input-checkbox">
				<p-radioButton
					gaModelChangeEvent="Match by 'index' (Product Exel Reader)"
					inputId="byIndex"
					name="matchType"
					[(ngModel)]="hasColumnTitles"
					[value]="false"
					(ngModelChange)="updateTable()"
				></p-radioButton>
				<label for="byIndex">Match by <b>Index</b></label>
			</div>
		</div>

		<div class="logical-blocks-container" *mcRerender="rerenderTrigger">
			<div class="logical-block">
				<div class="control-input-container label-top required">
					<label>SKU<span class="required-marker"></span></label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="SKU mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.sku"
							appendTo="body"
						></p-select>
					</div>
				</div>
				<div class="control-input-container label-top required">
					<label>Quantity<span class="required-marker"></span></label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Quantity mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							[autoDisplayFirst]="false"
							optionValue="value"
							[showClear]="true"
							[(ngModel)]="headersModel.quantity"
							appendTo="body"
						></p-select>
					</div>
				</div>
			</div>
			<div class="logical-block">
				<div class="control-input-container label-top">
					<label>Exp Date</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Exp Date mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							[autoDisplayFirst]="false"
							optionValue="value"
							[showClear]="true"
							[(ngModel)]="headersModel.expDate"
							appendTo="body"
						></p-select>
					</div>
				</div>
				<div class="control-input-container label-top">
					<label>Born Date</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Born Date mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.bornDate"
							appendTo="body"
						></p-select>
					</div>
				</div>
				<div class="control-input-container label-top">
					<label>Sell By</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Sell By mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.sellBy"
							appendTo="body"
						></p-select>
					</div>
				</div>
			</div>
			<div class="logical-block">
				<div class="control-input-container label-top">
					<label>Lot Number</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Lot Number mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.lotNumber"
							appendTo="body"
						></p-select>
					</div>
				</div>
				<div class="control-input-container label-top">
					<label>Cost</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="Cost mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.cost"
							appendTo="body"
						></p-select>
					</div>
				</div>
				<div class="control-input-container label-top">
					<label>S/N</label>
					<div class="input-box">
						<p-select
							gaModelChangeEvent="S/N mapped (Product Exel Reader)"
							[options]="headersList"
							[optionLabel]="hasColumnTitles ? 'title' : 'letter'"
							optionValue="value"
							[autoDisplayFirst]="false"
							[showClear]="true"
							[(ngModel)]="headersModel.serialNumber"
							appendTo="body"
						></p-select>
					</div>
				</div>
			</div>
		</div>
	</section>

	<hr/>
	<div class="rfid-btn-list t-right">
		<app-button
			gaEvent="Exel imported (Product Exel Reader)"
			[variant]="buttonVariantEnum.Primary"
			text="Import"
			[tooltip]="!selectedWorkbook ? 'Upload your file' : ''"
			[isDisabled]="!selectedWorkbook"
			(click)="import()"
		/>
		<ng-content></ng-content>
	</div>
</div>
