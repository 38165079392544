import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreModule } from '@core/core.module';
import LayoutModule from '@layout/layout.module';
import * as Sentry from '@sentry/angular';

import { MetaDataService } from '@shared/services';
import SharedModule from '@shared/shared.module';
import { AuthInterceptor } from 'angular-auth-oidc-client';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { AuthConfigModule } from 'src/app/auth-config.module';

import { GlobalErrorHandler } from '@core/services';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '@environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';

const devtools = environment.ngrxDevtoolsOn
	? [StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })]
	: [];

@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		AppRoutingModule,
		AuthConfigModule,
		LayoutModule,
		CoreModule,
		ToastModule,
		MessageModule,
		NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
		NgxGoogleAnalyticsRouterModule,
		StoreModule.forRoot({}),
		EffectsModule.forRoot([]),
		...devtools,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		MetaDataService,
		MessageService,
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimationsAsync(),
		providePrimeNG({
			theme: {
				preset: Aura,
				options: {
					darkModeSelector: 'none',
					cssLayer: {
						name: 'primeng',
						order: 'app-styles, primeng, another-css-library',
					},
				},
			},
		}),
	],
})
export class AppModule {}
