import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPrinterListViewModel } from '@shared/models/printing';
import { BehaviorSubject } from 'rxjs';
import { State } from '@shared/enums';
import { PrinterImageUrlHelper } from '@shared/util';
import { NavigationService } from '@shared/services';

@Component({
	selector: 'app-printers-short-list',
	templateUrl: './printers-short-list.component.html',
	styleUrl: './printers-short-list.component.scss',
	standalone: false,
})
export class PrintersShortListComponent {
	public printers$: BehaviorSubject<IPrinterListViewModel[]> = new BehaviorSubject<IPrinterListViewModel[]>([]);

	@Input() set printers(printerModels: IPrinterListViewModel[]) {
		this.printers$.next(printerModels);
	}

	@Output() onPrinterClick = new EventEmitter<number>();

	constructor(public navigationService: NavigationService) {}

	protected readonly printerStateEnum = State;
	protected readonly PrinterImageUrlHelper = PrinterImageUrlHelper;
}
