import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import moment from 'moment';
import { BehaviorSubject, Subject, combineLatest, filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BlockPosition, InputType } from '@shared/enums';

@UntilDestroy()
@Component({
	selector: 'app-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	standalone: false,
})
export class InputComponent {
	private _type$ = new BehaviorSubject<InputType>(InputType.text);
	private _value$ = new Subject<any>();

	@Input()
	set type(val: InputType) {
		this._type$.next(val);
	}

	get type(): InputType {
		return this._type$.value;
	}

	@Input() error: string | null | undefined = null;
	@Input() containerClass: string | null = null;
	@Input() placeholder: string | null = '';
	@Input() gaName: string | null = '';
	@Input() errorPosition: BlockPosition = BlockPosition.bottom;
	@Input() readonly: boolean = false;
	@Input() maxWidth: number | null = null;
	@Input() minWidth: number | null = null;
	@Input() minNumValue?: number;
	@Input() required: boolean = false;

	private _value: any;

	@Input()
	set value(val: any) {
		this._value$.next(val);
	}

	get value() {
		return this._value;
	}

	@Output() valueChange = new EventEmitter<any>();

	@Output() onKeyup = new EventEmitter<any>();

	public id: number;

	constructor(@Inject(LOCALE_ID) private locale: string) {
		this.id = Math.floor(Math.random() * 1000);

		combineLatest({
			type: this._type$,
			value: this._value$,
		})
			.pipe(
				untilDestroyed(this),
				filter(_ => _.value != null || _.value != undefined),
			)
			.subscribe(resp => {
				if (resp.type == InputType.date && moment(resp.value).isValid()) {
					this._value = formatDate(resp.value, 'yyyy-MM-dd', this.locale);
				} else {
					this._value = resp.value;
				}
			});
	}

	public valueChanged() {
		this.valueChange.emit(this._value);
	}

	public keyup() {
		this.onKeyup.emit(this._value);
	}
}
