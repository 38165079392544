import { Component } from '@angular/core';
import { FilterService } from 'primeng/api';
import { MetaDataService } from '@shared/services';
import { CustomerSelectorComponent } from '@core/components';

@Component({
	selector: 'customer-filter',
	templateUrl: './customer-filter.component.html',
	styleUrls: ['./customer-filter.component.scss'],
	standalone: false,
})
export class CustomerFilterComponent extends CustomerSelectorComponent {
	constructor(filterService: FilterService, metaDataService: MetaDataService) {
		super(filterService, metaDataService);
	}
}
