<div class="control-input-container" [ngClass]="{ 'has-error-message': error, 'without-label': !label, required: required }">
	@if (label) {
		<label>{{ label }}<span class="required-marker"></span></label>
	}
	@if (loading) {
		<skeleton [maxWidth]="400"></skeleton>
	} @else {
		@if (readonly) {
			<div class="input-box notranslate">
				<span class="readonly-input">{{ selectedCustomer?.name }}</span>
			</div>
		} @else {
			<div class="input-box">
				<p-autoComplete
					gaModelChangeEvent="Customer selector changed"
					[(ngModel)]="selectedCustomer"
					[suggestions]="filteredCustomers"
					[showClear]="showClear"
					(completeMethod)="filterCustomers($event)"
					(ngModelChange)="onModelChange($event)"
					panelStyleClass="notranslate"
					appendTo="body"
					field="name"
					placeholder="Select Customer"
					[dropdown]="true"
					[forceSelection]="true"
				>
				</p-autoComplete>
			</div>
		}
	}
	@if (error) {
		<div [ngClass]="BlockPosition.bottom">
			<p-message severity="error" summary="Error" [text]="this.error"></p-message>
		</div>
	}
</div>
