import { Component, Input } from '@angular/core';

@Component({
	selector: 'table-sort-icon',
	templateUrl: './table-sort-icon.component.html',
	styleUrl: './table-sort-icon.component.scss',
	standalone: false,
})
export class TableSortIconComponent {
	@Input() sortOrder: number = 0;
}
