import { Component, Input } from '@angular/core';

@Component({
	selector: 'drop-zone',
	templateUrl: './drop-zone.component.html',
	styleUrl: './drop-zone.component.scss',
	standalone: false,
})
export class DropZoneComponent {
	@Input() placeholder: string = 'Drag and drop a file here or click to upload';
	@Input() width: number = 300;
	@Input() height: number = 300;
	@Input() type: 'file' | 'img' = 'file';

	isDragging = false;
	file: File | null = null;

	onDragOver(event: DragEvent) {
		event.preventDefault();
		this.isDragging = true;
	}

	onDragLeave(event: DragEvent) {
		event.preventDefault();
		this.isDragging = false;
	}

	onDrop(event: DragEvent) {
		event.preventDefault();
		this.isDragging = false;

		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			this.file = event.dataTransfer.files[0];
			// Process the file here
			console.log('Dropped file:', this.file);
		}
	}

	onFileSelected(event: Event) {
		const input = event.target as HTMLInputElement;
		if (input.files && input.files.length > 0) {
			this.file = input.files[0];
			// Process the file here
			console.log('Selected file:', this.file);
		}
	}

	triggerFileInput(fileInput: HTMLInputElement) {
		fileInput.click();
	}
}
