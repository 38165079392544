import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[maxValue]',
	standalone: false,
})
export class MaxValueDirective {
	@Input() maxValue?: number;

	constructor(
		private el: ElementRef,
		private ngControl: NgControl,
	) {}

	@HostListener('input', ['$event']) onInput(event: Event): void {
		if (this.maxValue) {
			const inputValue = +this.el.nativeElement.value;

			if (inputValue > this.maxValue) {
				this.ngControl.control!.setValue(this.maxValue);
			}
		}
	}
}

@Directive({
	selector: '[minValue]',
	standalone: false,
})
export class MinValueDirective {
	@Input() minValue?: number;

	constructor(
		private el: ElementRef,
		private ngControl: NgControl,
	) {}

	@HostListener('input', ['$event']) onInput(event: Event): void {
		if (this.minValue || this.minValue == 0) {
			const inputValue = +this.el.nativeElement.value;

			if (inputValue < this.minValue) {
				this.ngControl.control!.setValue(this.minValue);
			}
		}
	}
}
