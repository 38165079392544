import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InputType } from '@shared/enums';
import { PaginatorState } from 'primeng/paginator';
import { BehaviorSubject } from 'rxjs';
import { OffsetPagination } from 'src/app/shared/models';

@Component({
	selector: 'paginator',
	templateUrl: './paginator.component.html',
	styleUrls: ['./paginator.component.scss'],
	standalone: false,
})
export class PaginatorComponent {
	public inputTypeEnum = InputType;
	public pager$ = new BehaviorSubject<OffsetPagination>(new OffsetPagination(0, 10));
	public totalCount$ = new BehaviorSubject<number>(0);
	public rerenderTrigger = 0;

	@Input() showJumpToPageInput = true;

	@Input()
	public set pager(val: OffsetPagination | null) {
		if (val) {
			if (val.take > 100) {
				val.take = 100;
				this.rerenderTrigger++;
			} else if (val.take < 1) {
				val.take = 1;
				this.rerenderTrigger++;
			}
			this.pager$.next(val);
		}
	}

	@Output() pagerChange = new EventEmitter<OffsetPagination>();

	@Input() public set totalCount(val: number) {
		this.totalCount$.next(val);
	}

	public onPageChange(event: PaginatorState) {
		if (!event.rows) {
			return;
		}

		const first = event.first ?? 0;
		this.pagerChange.emit(new OffsetPagination(first, +event.rows));
	}

	public validatePositive(event: any): boolean {
		return (event.charCode != 8 && event.charCode == 0) || (event.charCode >= 48 && event.charCode <= 57);
	}
}
