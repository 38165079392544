import { Directive, Input, HostListener, Inject } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
	selector: '[expandRow]',
	standalone: false,
})
export class RowExpandToggleDirective {
	@Input('selectedRow') selectedRow: any;

	constructor(@Inject(DatatableComponent) private dataTable: DatatableComponent) {}

	@HostListener('click')
	public onClick() {
		this.dataTable.rowDetail.toggleExpandRow(this.selectedRow);
	}
}
