<div class="printer-short-list-container">
	@for (printer of printers$ | async; track printer) {
		<a
			class="printer-short-list-item"
			tooltipStyleClass="printer-info-tooltip"
			[pTooltip]="tooltipContent"
			tooltipPosition="bottom"
			(click)="onPrinterClick.emit(printer.id)"

		>
			<div class="img-box">
				<img
					[ngClass]="{ 'station-offline--opacity': printer.status == printerStateEnum.Offline }"
					[src]="PrinterImageUrlHelper.getImageUrl(printer.name)"
					alt="Printer image"
				/>
				<span class="status-marker" [ngClass]="'bgc-' + printer.status"></span>
				@if (printer.issueDescription) {
					<div class="circle-warning-marker">
						<i class="mdi mdi-alert-outline"></i>
					</div>
				}
			</div>
		</a>

		<ng-template #tooltipContent>
			<div class="printer-item-tooltip-content">
				<div class="img-box">
					<img
						[ngClass]="{ 'station-offline--opacity': printer.status == printerStateEnum.Offline }"
						[src]="PrinterImageUrlHelper.getImageUrl(printer.name)"
						alt="Printer image"
					/>
					<span class="status-marker" [ngClass]="'bgc-' + printer.status"></span>
				</div>

				<div class="printer-tooltip-info-container">
					<div class="printer-item-title">
						{{ printer.displayName }}
					</div>

					<div class="description">
						@if (printer.displayName !== printer.name) {
							<p><span class="label">Model:</span> {{ printer.name }}</p>
						}
						<p><span class="label">Connection:</span> {{ printer.connectedBy }}</p>
						<p>
							<span class="label">DPI:</span>
							{{ printer.dpi }}
						</p>
					</div>
					@if (printer.issueDescription) {
						<p class="badge badge-warning">
							<i class="warning-marker mdi mdi-alert-outline mr-1 static-icon"></i>
							{{ printer.issueDescription }}
						</p>
					}
				</div>
			</div>
		</ng-template>
	}
</div>
