import { Component, Input } from '@angular/core';

@Component({
	selector: 'skeleton-table',
	templateUrl: './skeleton-table.component.html',
	styleUrls: ['./skeleton-table.component.scss'],
	standalone: false,
})
export class SkeletonTableComponent {
	private _rows: number = 5;
	private _cols: number = 5;
	public rowsArray: number[];
	public colsArray: number[];

	@Input()
	set rows(val: number) {
		this._rows = val;
		this.rowsArray = this.getRowArray(this._rows);
	}

	@Input()
	set cols(val: number) {
		this._cols = val;
		this.colsArray = this.getRowArray(this._cols);
	}

	constructor() {
		this.rowsArray = this.getRowArray(this._rows);
		this.colsArray = this.getRowArray(this._cols);
	}

	private getRowArray(count: number): number[] {
		return new Array(count).fill(0).map((x, i) => i);
	}
}
