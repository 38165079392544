import { Directive, HostListener } from '@angular/core';

@Directive({
	selector: '[stop-redirect]',
	standalone: false,
})
export class StopRedirectDirective {
	constructor() {}

	@HostListener('click', ['$event'])
	public onClick(event: any): void {
		//to stop the redirect, router links need both of these events
		event.preventDefault();
		event.stopPropagation();
	}
}
