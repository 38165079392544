import { throwError } from 'rxjs';
import { CustomErrors, ErrorModel } from '@shared/models';

export function throwCustomError(code: CustomErrors, key?:string, message?:string, data?:Record<string, any>) {
	const error: ErrorModel = {
		message: message ? message : '',
		key: key ? key : generateUniqueId(),
		code: code,
		data: data ? data : {},
	};
	return throwError({ error: error });
}

// Doesn't guarantee true uniqueness like GUID
export function generateUniqueId(): string {
	return Math.floor(Math.random() * Date.now()).toString(16);
}
