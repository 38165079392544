import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocationService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocationLightModel } from '@shared/models';
import { FilterService } from 'primeng/api';
import { BehaviorSubject, Subject, combineLatest, filter, shareReplay, switchMap, tap } from 'rxjs';
import { LocationOrderField } from '@core/models';
import { LocationType, OrderDirection } from '@shared/enums';
import { BaseLocationFilter } from '@core/abstractions';

@UntilDestroy()
@Component({
	selector: 'location-selector',
	templateUrl: './location-selector.component.html',
	styleUrls: ['./location-selector.component.scss'],
	standalone: false,
})
export class LocationSelectorComponent extends BaseLocationFilter {
	private _location: LocationLightModel | undefined = undefined;
	private _isLocked = true;

	@Output() locationChange = new EventEmitter<LocationLightModel | undefined>();

	@Input() get location(): LocationLightModel | undefined {
		return this._location;
	}

	set location(val: LocationLightModel | undefined) {
		this._location = val;
	}

	@Input()
	set isLocked(val: boolean) {
		if (!val) {
			this.getLocations$.next();
		}
		this._isLocked = val;
	}

	get isLocked() {
		return this._isLocked;
	}

	private getLocations$ = new Subject<void>();

	public loading$ = new BehaviorSubject<boolean>(false);
	public eLocationType = LocationType;
	private _customerId$ = new BehaviorSubject<number | undefined>(undefined);

	@Input() set vendorId(val: number | undefined | null) {
		if (val) {
			this._customerId$.next(val);
		}
		this.location = undefined;
	}

	get vendorId(): number | undefined {
		return this._customerId$.value;
	}

	@Input() showClear: boolean = false;

	constructor(
		filterService: FilterService,
		private locationService: LocationService,
	) {
		super(filterService);

		const locationSource$ = this._customerId$.pipe(
			filter(customerId => customerId != undefined),
			switchMap(customerId => {
				if (customerId != undefined) {
					const request = {
						customerIds: [customerId],
						orderBy: LocationOrderField.CustomerId,
						orderDirection: OrderDirection.Ascending,
					};
					return this.locationService.getAll(request);
				} else {
					return [];
				}
			}),
			shareReplay(),
		);

		this.getLocations$
			.pipe(
				tap(_ => {
					if (this._customerId$.value) {
						this.loading$.next(true);
					}
				}),
				switchMap(_ => {
					return combineLatest({
						locations: locationSource$,
						selectedVendor: this._customerId$,
					});
				}),
				untilDestroyed(this),
			)
			.subscribe(resp => {
				this._groupedLocations = this.groupLocation(resp.locations, resp.selectedVendor);
				this.loading$.next(false);
			});
	}
}
