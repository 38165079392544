import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
	standalone: false,
})
export class ButtonComponent {
	public buttonVariantEnum = ButtonVariant;

	@Input() variant?: ButtonVariant = ButtonVariant.Default;
	@Input() text?: string;
	@Input() icon?: string;
	@Input() tooltip?: string;
	@Input() tooltipAlign?: TooltipAlign = TooltipAlign.Top;
	@Input() customClass?: string;
	@Input() isDisabled?: boolean | null = false;
	@Input() onRouterLink?: string | any[] | null;
	@Input() onClick?: () => void;

	protected handleClick() {
		if (this.onClick) {
			this.onClick();
		}
	}
}

export enum TooltipAlign {
	Top = 'top',
	Right = 'right',
	Left = 'left',
	Bottom = 'bottom',
}

export enum ButtonVariant {
	Primary = 'primary',
	Light = 'light',
	Danger = 'danger',
	Icon = 'icon',
	Default = 'default',
}

export interface ButtonModel {
	variant?: ButtonVariant;
	isDisabled?: boolean | null;
	customClass?: string;
	gaEvent?: string;
	text?: string | undefined;
	icon?: string | undefined;
	onClick?: () => void;
	routerLink?: string | any[] | null | undefined;
	tooltip?: string | undefined;
	tooltipAlign?: TooltipAlign;
}
