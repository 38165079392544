export class ArrayHelper {
	public static chunkArray<TValue>(array: TValue[], chunkSize: number): TValue[][] {
		const numberOfChunks = Math.ceil(array.length / chunkSize);
		const chunks: TValue[][] = [];

		for (let i = 0; i < numberOfChunks; i++) {
			const start = i * chunkSize;
			const end = start + chunkSize;

			chunks.push(array.slice(start, end));
		}

		return chunks;
	}

	public static uniqueChunkArray<TValue>(array: TValue[], chunkSize: number): TValue[][] {
		const uniqueArray = [...new Set<TValue>(array)]
		return this.chunkArray(uniqueArray, chunkSize);
	}
}
