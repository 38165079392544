import { Injectable } from '@angular/core';
import { PrintProductProperty } from '@core/enums';
import { IPrintProductTransferModel } from '@core/models';
import { ITemplate } from '@printing/models/itemplate';
import { IHeader } from '@shared/models';
import { IProductSearchModel, ProductViewModel } from '@shared/models/products';
import { IProductFilter, ProductService } from '@shared/services';
import { ExcelHelperService } from '@shared/services/excel-helper.service';
import { CaseInsensitiveRecord } from '@shared/util';
import * as ExcelJS from 'exceljs';
import { Observable, map, of, from, mergeMap } from 'rxjs';
import { ArrayHelper } from '@core/utils/array.helper';

@Injectable({
	providedIn: 'root',
})
export class PrintingProductExcelService {
	public readonly sheetName: string = 'Printing';
	private readonly templateName: string = 'PrintProduct_Template.xlsx';

	public headers: IHeader[] = [
		{ displayValue: 'Sku', key: PrintProductProperty.sku, required: true, value: null },
		{ displayValue: 'Quantity', key: PrintProductProperty.quantity, required: true, value: null },
		{ displayValue: 'Lot Number', key: PrintProductProperty.lotNumber, required: false, value: null },
		{ displayValue: 'Exp Date', key: PrintProductProperty.expDate, required: false, value: null },
		{ displayValue: 'Cost', key: PrintProductProperty.cost, required: false, value: null },
		{ displayValue: 'Born Date', key: PrintProductProperty.bornDate, required: false, value: null },
		{ displayValue: 'S/N', key: PrintProductProperty.serialNumber, required: false, value: null },
		{ displayValue: 'Sell By', key: PrintProductProperty.sellBy, required: false, value: null },
	];

	constructor(
		private excelHelper: ExcelHelperService,
		private productService: ProductService,
	) {}

	public getTemplate(): Observable<ITemplate> {
		return this.excelHelper.generateTemplate(this.headers, this.sheetName).pipe(
			map(_ => {
				return {
					file: _,
					fileName: this.templateName,
				};
			}),
		);
	}

	public getOriginHeaders(workbook: ExcelJS.Workbook): Record<string, number> {
		const ws = workbook.worksheets[0];
		return this.excelHelper.getOriginHeaders(ws);
	}

	public readData(
		workbook: ExcelJS.Workbook,
		headers: Record<string, number>,
		hasColumnTitles: boolean,
	): Observable<ProductViewModel[]> {
		const readProducts: IPrintProductTransferModel[] = this.privateReadData(workbook, headers, hasColumnTitles);
		const products: Observable<ProductViewModel[]> = this.parseData(readProducts);
		return products;
	}

	private parseData(readProducts: IPrintProductTransferModel[]): Observable<ProductViewModel[]> {
		const uniqueSkus = readProducts.filter(_ => _.sku).map(_ => _.sku!);

		if (uniqueSkus.length > 0) {
			const filters: IProductFilter[] = ArrayHelper.uniqueChunkArray(uniqueSkus, 50).map(skus => {
				let filter: IProductFilter = { skus: skus, deleted: false };
				return filter;
			});

			return from(filters).pipe(
				mergeMap(_ => {
					return this.productService.Search(_);
				}),
				map(resp => {
					const productsDictionary = resp.reduce<CaseInsensitiveRecord<IProductSearchModel>>(
						(acc, cur) => {
							acc[cur.sku] = cur;
							return acc;
						},
						new CaseInsensitiveRecord<IProductSearchModel>(),
					);

					return readProducts.map(_ => this.toProductModel(_, productsDictionary));
				}),
			);
		} else {
			return of([]);
		}
	}

	private toProductModel(
		readProduct: IPrintProductTransferModel,
		productsDictionary: Record<string, IProductSearchModel>,
	): ProductViewModel {
		let productToAdd: ProductViewModel;
		if (readProduct.sku == undefined) {
			productToAdd = ProductViewModel.GetUnknown();
		} else {
			const product = productsDictionary[readProduct.sku];
			if (!product) {
				productToAdd = ProductViewModel.GetUnknown();
				productToAdd.sku = readProduct.sku;
			} else {
				productToAdd = new ProductViewModel(
					product.productId,
					product.name,
					product.title,
					product.sku,
					product.itemsPerTag,
					0,
				);
			}
		}

		if (readProduct.quantity) {
			productToAdd.count = parseInt(readProduct.quantity, 10);
		}

		if (readProduct.cost) {
			productToAdd.cost = parseInt(readProduct.cost, 10);
		}

		if (readProduct.bornDate) {
			productToAdd.bornDate = new Date(readProduct.bornDate);
		}

		if (readProduct.sellBy) {
			productToAdd.sellBy = new Date(readProduct.sellBy);
		}

		if (readProduct.expDate) {
			productToAdd.expirationDate = new Date(readProduct.expDate);
		}

		productToAdd.serialNumber = readProduct.serialNumber;
		productToAdd.lotNumber = readProduct.lotNumber;
		return productToAdd;
	}

	private privateReadData(
		workbook: ExcelJS.Workbook,
		headers: Record<string, number>,
		hasColumnTitles: boolean,
	): IPrintProductTransferModel[] {
		const ws: ExcelJS.Worksheet = workbook.worksheets[0];

		if (ws.actualRowCount <= 1) {
			return [];
		}

		let startRowIndex = 1;
		let endRowIndex = ws.actualRowCount;

		if (hasColumnTitles) {
			startRowIndex = 2;
			endRowIndex = ws.actualRowCount - 1;
		}

		const readProducts = ws.getRows(startRowIndex, endRowIndex)!.map(row => {
			const product: IPrintProductTransferModel = {};

			this.setProductValue(PrintProductProperty.sku, product, row, headers);

			this.setProductValue(PrintProductProperty.quantity, product, row, headers);

			this.setProductValue(PrintProductProperty.bornDate, product, row, headers);

			this.setProductValue(PrintProductProperty.sellBy, product, row, headers);

			this.setProductValue(PrintProductProperty.expDate, product, row, headers);

			this.setProductValue(PrintProductProperty.cost, product, row, headers);

			this.setProductValue(PrintProductProperty.serialNumber, product, row, headers);

			this.setProductValue(PrintProductProperty.lotNumber, product, row, headers);

			return product;
		});

		return readProducts;
	}

	private setProductValue(
		property: PrintProductProperty,
		product: IPrintProductTransferModel,
		row: ExcelJS.Row,
		headers: Record<string, number>,
	) {
		const index = headers[property];
		if (index) {
			const value = row.getCell(index).value?.toString();
			if (value) {
				product[property] = value;
			}
		}
	}
}
