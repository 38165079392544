export interface ErrorModel {
	message: string;
	key: string;
	code: CustomErrors;
	data: Record<string, any>;
}

export enum CustomErrors {
	OrderNameNotUnique = 2_01_005,

	OrderItemProductIdRequired = 2_02_009,
	ProductNameRequired = 2_02_011,

	Forbidden = 2_03_002,

	PrintJobTagsEmpty = 2_08_002,

	CustomerNameRequired = 2_09_001,
	CustomerAbbreviationEmpty = 2_09_002,
	CustomerNotificationEmailEmpty = 2_09_003,

	CustomerLayoutsForeignKey = 2_10_001,
	CustomerLayoutsIdRequired = 2_10_002,

	CompanyPrefixesValueUnique = 2_11_001,
	CompanyPrefixesInvalidPrefix = 2_11_002,
	CompanyPrefixesCantDelete = 2_11_004,

	ProductMediaNotFound = 2_12_001,

	CustomerSettingsNotFound = 2_14_001,
	CustomerSettingsEmpty = 2_14_002,
	CustomerSettingsAlreadyExist = 2_14_003,

	ZplInvalid = 2_15_001,
	ZplBodyToBig = 2_15_002,

	PrintStationNotFound = 5_01_002,
	PrintStationOffline = 5_01_003,
	ClientIdInvalid = 5_01_004,

	DocumentsIdsInvalid = 5_02_001,
	NotInQueue = 5_02_002,
	AlreadyInQueue = 5_02_003,
	DocumentIdRequired = 5_02_004,
	AlreadyCreated = 5_02_005,

	PrinterNotFound = 5_03_001,
	PrinterOffline = 5_03_002,
	NotesLengthExceeded = 5_03_004,

	PermissionRequired = 6_01_001,

	UserAlreadyExists = 6_02_001,
	FirstNameEmpty = 6_02_003,
	LastNameEmpty = 6_02_004,
	PhoneIncorrect = 6_02_005,
	EmailEmpty = 6_02_006,
	EmailIncorrect = 6_02_007,
	UserNotFound = 6_02_008,
}

export const CustomErrorMessage: Record<CustomErrors, string> = {
	[CustomErrors.OrderNameNotUnique]: 'Name is not unique.',
	[CustomErrors.OrderItemProductIdRequired]: "Can't save order. Some of products are unknown.",
	[CustomErrors.ProductNameRequired]: 'Can`t print. Product Name required',
	[CustomErrors.Forbidden]: 'Access denied.',
	[CustomErrors.PrintJobTagsEmpty]: 'PrintJob have nothing to print',
	[CustomErrors.CustomerNameRequired]: "Name can't be empty.",
	[CustomErrors.CustomerAbbreviationEmpty]: "Abbreviation field can't be empty string.",
	[CustomErrors.CustomerNotificationEmailEmpty]: "Notification field can't be empty string.",
	[CustomErrors.CustomerLayoutsForeignKey]: "Reference key of layout isn't exist.",
	[CustomErrors.CustomerLayoutsIdRequired]: "Layout's id isn't valid.",
	[CustomErrors.CompanyPrefixesValueUnique]: "Reference key of prefix isn't exist.",
	[CustomErrors.CompanyPrefixesInvalidPrefix]: 'Prefixes Values must be digit and have a length between 6 and 12.',
	[CustomErrors.CompanyPrefixesCantDelete]: "Can't delete. Some of company prefixes are already in use.",
	[CustomErrors.ZplInvalid]: "Can't save. Zpl invalid.",
	[CustomErrors.ZplBodyToBig]: "Can't save. Too many objects at the label or image is too big.",
	[CustomErrors.PrintStationNotFound]: "Can't print. Station not found.",
	[CustomErrors.PrintStationOffline]: "Can't print. Station Offline.",
	[CustomErrors.ClientIdInvalid]: "Can't print. ClientId invalid.",
	[CustomErrors.DocumentsIdsInvalid]: "Can't print. Please contact with support.",
	[CustomErrors.NotInQueue]: "Can't print. Please contact with support.",
	[CustomErrors.AlreadyInQueue]: "Can't print. Please contact with support.",
	[CustomErrors.DocumentIdRequired]: "Can't print. Please contact with support.",
	[CustomErrors.AlreadyCreated]: "Can't print. Please contact with support.",
	[CustomErrors.PrinterNotFound]: "Can't print. Printer not found.",
	[CustomErrors.PrinterOffline]: "Can't calibrate. Printer is Offline.",
	[CustomErrors.NotesLengthExceeded]: 'Notes length is exceeded',
	[CustomErrors.PermissionRequired]: "Can't save. You should select some permissions.",
	[CustomErrors.UserAlreadyExists]: 'User with this email is already exist',
	[CustomErrors.FirstNameEmpty]: 'First Name field must be filled out.',
	[CustomErrors.LastNameEmpty]: 'Last Name field must be filled out.',
	[CustomErrors.PhoneIncorrect]: 'The phone number is filled out incorrectly.',
	[CustomErrors.EmailEmpty]: 'The Email field must be filled out.',
	[CustomErrors.EmailIncorrect]: 'The Email is filled out incorrectly.',
	[CustomErrors.UserNotFound]: 'User not found.',
	[CustomErrors.CustomerSettingsNotFound]: 'Setting not found.',
	[CustomErrors.CustomerSettingsEmpty]: "Email can't empty string.",
	[CustomErrors.CustomerSettingsAlreadyExist]: 'This email is already in use.',
	[CustomErrors.ProductMediaNotFound]: 'Product media not found.',
};

export type ErrorHandlerDictionary = Partial<Record<CustomErrors, (param: ErrorModel) => void>>;

// Doesn't guarantee true uniqueness like GUID
export function generateUniqueId(): string {
	return Math.floor(Math.random() * Date.now()).toString(16);
}
