import { Component, Input } from '@angular/core';
import { ITableHeader } from '@shared/models';

@Component({
	selector: 'table-loading-body',
	templateUrl: './table-loading-body.component.html',
	styleUrl: './table-loading-body.component.scss',
	standalone: false,
})
export class TableLoadingBodyComponent<T> {
	protected _rows: number = 1;
	@Input() columns: ITableHeader<T>[] = [];

	@Input() set rows(val: number | undefined) {
		if (val) {
			this._rows = val;
		} else {
			this._rows = 1;
		}
	}
}
