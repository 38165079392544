import { Component, OnInit } from '@angular/core';
import { ButtonVariant } from '@shared/components';

@Component({
	selector: 'gallery',
	templateUrl: './gallery.component.html',
	styleUrls: ['./gallery.component.scss'],
	standalone: false,
})
export class GalleryComponent implements OnInit {
	images: item[];
	position: 'bottom' | 'top' | 'left' | 'right' | undefined = 'left';
	_activeIndex: number = 0;

	get activeIndex(): number {
		return this._activeIndex;
	}

	set activeIndex(newValue) {
		if (this.images && 0 <= newValue && newValue <= this.images.length - 1) {
			this._activeIndex = newValue;
		}
	}

	public buttonVariantEnum = ButtonVariant;

	constructor() {
		this.images = [
			{
				itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1.jpg',
				thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria1s.jpg',
				alt: 'Description for Image 1',
			},
			{
				itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2.jpg',
				thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria2s.jpg',
				alt: 'Description for Image 2',
			},
			{
				itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3.jpg',
				thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria3s.jpg',
				alt: 'Description for Image 3',
			},
			{
				itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4.jpg',
				thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4s.jpg',
				alt: 'Description for Image 4',
			},
			{
				itemImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4.jpg',
				thumbnailImageSrc: 'https://primefaces.org/cdn/primeng/images/galleria/galleria4s.jpg',
				alt: 'Description for Image 4',
			},
		];
	}

	ngOnInit() {}

	next() {
		this.activeIndex++;
	}

	prev() {
		this.activeIndex--;
	}
}

export interface item {
	itemImageSrc: string;
	thumbnailImageSrc: string;
	alt: string;
}
