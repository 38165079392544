import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { InputComponent } from '@shared/components';

@Component({
	selector: 'labeled-input',
	templateUrl: './labeled-input.component.html',
	styleUrls: ['./labeled-input.component.scss'],
	standalone: false,
})
export class LabeledInputComponent extends InputComponent {
	@Input() label: string = '';

	constructor(@Inject(LOCALE_ID) locale: string) {
		super(locale);
	}
}
