import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPrinterFilter } from '@core/models';
import { environment } from '@environments/environment';
import { ConnectionType, Dpi, State } from '@shared/enums';
import { OffsetCollection, OffsetPagination } from '@shared/models';
import { IPrinterModel } from '@shared/models/printing';
import { ChunkService } from '@shared/services';
import { Observable } from 'rxjs';
import { IPrinterUpdateModel } from '@printing/models';
@Injectable({
	providedIn: 'root',
})
export class PrinterService {
	private readonly printServiceUrl: string;

	constructor(
		private http: HttpClient,
		private chunkService: ChunkService,
	) {
		this.printServiceUrl = environment.printServiceBaseAddress;
	}

	public getAllPrinters(clientIds: string[], serialNumber: string[] = []): Observable<IPrinterModel[]> {
		const url = `${this.printServiceUrl}/api/v1/stations/printers`;
		const request: IPrinterFilter = {
			clientIds: clientIds,
			serialNumbers: serialNumber,
		};
		return this.chunkService.getAll<IPrinterResponse, IPrinterFilter>(request, url);
	}

	public getManyPrinters(
		filter: IPrinterFilter,
		pager: OffsetPagination,
	): Observable<OffsetCollection<IPrinterModel>> {
		const url = `${this.printServiceUrl}/api/v1/stations/printers`;
		return this.chunkService.get<IPrinterModel, IPrinterFilter>(pager, filter, url);
	}

	public updatePrinter(clientId: string, serialNumber: string, model: IPrinterUpdateModel) {
		const request: IPrinterUpdateRequest = {};

		if (model.notes) {
			request.notes = model.notes;
		}
		return this.http.post(
			`${this.printServiceUrl}/api/v1/stations/${clientId}/printers/${serialNumber}/settings`,
			request,
		);
	}
}

interface IPrinterUpdateRequest {
	notes?: string;
}

interface IPrinterResponse {
	id: number;
	name: string;
	serialNumber: string;
	status: State;
	displayName: string;
	dpi: Dpi;
	disconnected: Date;
	setupDate: Date;
	clientId: string;
	firmware: string;
	connectedBy: ConnectionType;
	issueDescription?: string;
	notes?: string;
}
