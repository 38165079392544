import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
	ButtonComponent,
	CompanyPrefixInputComponent,
	ConfirmDialogComponent,
	DropZoneComponent,
	GalleryComponent,
	InputComponent,
	LabeledInputComponent,
	PaginatorComponent,
	SearchComponent,
	SkeletonComponent,
	SkeletonTableComponent,
	SpinnerComponent,
	TableHeadersComponent,
	TableLoadingBodyComponent,
} from '@shared/components';
import {
	GaModelChangeEventDirective,
	MaxValueDirective,
	MinValueDirective,
	RowExpandToggleDirective,
	StopRedirectDirective,
} from '@shared/directives';
import { CastPipe, EnumToArrayPipe, LocalDatePipe } from '@shared/pipes';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { NgxRerenderModule } from 'ngx-rerender';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { MessageModule } from 'primeng/message';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TableSortIconComponent } from './components/table/table-sort-icon/table-sort-icon.component';
import { ToastModule } from 'primeng/toast';
import { RouterModule } from '@angular/router';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollerModule } from 'primeng/scroller';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardComponent } from './components/card/card.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {FormsModule} from "@angular/forms";

@NgModule({
	declarations: [
		CastPipe,
		LocalDatePipe,
		PaginatorComponent,
		GalleryComponent,
		StopRedirectDirective,
		EnumToArrayPipe,
		SpinnerComponent,
		CompanyPrefixInputComponent,
		InputComponent,
		LabeledInputComponent,
		RowExpandToggleDirective,
		DropZoneComponent,
		TableHeadersComponent,
		TableLoadingBodyComponent,
		SkeletonComponent,
		SkeletonTableComponent,
		TableSortIconComponent,
		ButtonComponent,
		SearchComponent,
		ConfirmDialogComponent,
		MinValueDirective,
		MaxValueDirective,
		CardComponent,
	],
	imports: [
		PaginatorModule,
		GalleriaModule,
		TooltipModule,
		NgxRerenderModule,
		MessageModule,
		NgxGoogleAnalyticsModule,
		GaModelChangeEventDirective,
		TableModule,
		RouterModule,
		ToastModule,
		ConfirmDialogModule,
		FormsModule,
	],
	exports: [
		CommonModule,
		RowExpandToggleDirective,
		GaModelChangeEventDirective,
		PaginatorModule,
		PaginatorComponent,
		GalleryComponent,
		DialogModule,
		LocalDatePipe,
		CastPipe,
		StopRedirectDirective,
		EnumToArrayPipe,
		SpinnerComponent,
		TooltipModule,
		SkeletonComponent,
		SkeletonTableComponent,
		CompanyPrefixInputComponent,
		TableModule,
		InputComponent,
		LabeledInputComponent,
		NgxGoogleAnalyticsModule,
		DropZoneComponent,
		TableHeadersComponent,
		TableLoadingBodyComponent,
		TableSortIconComponent,
		ButtonComponent,
		SearchComponent,
		ConfirmDialogComponent,
		RadioButtonModule,
		AccordionModule,
		CheckboxModule,
		ToastModule,
		ScrollerModule,
		AutoCompleteModule,
		MinValueDirective,
		MaxValueDirective,
		CardComponent,
		ConfirmDialogModule,
	],
	providers: [],
})
export default class SharedModule {}
