import { Injectable } from '@angular/core';
import { AppMessageService } from '@core/services';
import { CustomErrorMessage, CustomErrors, ErrorHandlerDictionary } from '@shared/models';
import { EMPTY, MonoTypeOperatorFunction, of, pipe, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ValidatorErrorHandlerService {
	constructor(private appMessageService: AppMessageService) {}

	catchError<T>(errorsDictionary: ErrorHandlerDictionary = {}): MonoTypeOperatorFunction<T> {
		return pipe(
			catchError((err, caught) => {
				if (!!err.error?.key && !!err.error?.code) {
					const key: CustomErrors = err.error.code;

					const hasErrorCodeProcessor = errorsDictionary.hasOwnProperty(key);

					if (hasErrorCodeProcessor) {
						errorsDictionary[key]!(err.error);
					} else {
						let message = CustomErrorMessage[key];

						if (!message) {
							message = err.error.message;
						}

						this.appMessageService.error(message);
					}
				} else {
					this.appMessageService.error(err.error.message);
				}
				return EMPTY;
			}),
		);
	}

	suppressErrors<T>(suppressDictionary: CustomErrors[] = []): MonoTypeOperatorFunction<T | undefined> {
		return pipe(
			catchError((err, caught) => {
				if (!!err.error?.key && !!err.error?.code) {
					const key: CustomErrors = err.error.code;

					if (suppressDictionary.includes(key)) {
						return of(undefined);
					}
				}
				return throwError(() => err);
			}),
		);
	}
}
