import { Injectable } from '@angular/core';
import { IOrderItemModel } from '@orders/models';
import { ITreeProductModel, TreeProductViewModel } from '@products/models';
import {
	IProductSearchModel,
	IProductSearchViewModel,
	ProductViewModel,
} from '@shared/models/products';
import { environment } from '@environments/environment';

@Injectable()
export abstract class ProductHelper {
	constructor() {}

	public static getProductViewModelWithOrderItem(
		orderItem: IOrderItemModel,
		product: IProductSearchModel
	): ProductViewModel {
		if (!orderItem || !product) {
			// Return default model if orderItem was deleted
			return new ProductViewModel(
				0,
				'No Name',
				'This item has been removed',
				'No SKU',
				0
			);
		}
		const thumbnailUrl = this.GetProductThumbnailUrl(product.productId);
		return new ProductViewModel(
			orderItem.productId,
			product.name,
			product.title,
			product.sku,
			product.itemsPerTag,
			orderItem.quantity,
			orderItem.id,
			orderItem.destinationVerified,
			orderItem.originVerified,
			orderItem.bornDate,
			orderItem.expDate,
			orderItem.sellBy,
			orderItem.lotNumber,
			orderItem.cost,
			orderItem.sn,
			thumbnailUrl,
		);
	}

	public static getProductViewModel(product: IProductSearchViewModel): ProductViewModel {
		const thumbnailUrl = this.GetProductThumbnailUrl(product.productId);

		return new ProductViewModel(
			product.productId,
			product.name,
			product.title,
			product.sku,
			product.itemsPerTag,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			thumbnailUrl,
		);
	}

	public static getTreeProductViewModel(
		product: ITreeProductModel,
	): TreeProductViewModel {
		const thumbnailUrl = this.GetProductThumbnailUrl(product.id);

		return new TreeProductViewModel(
			product.id,
			product.customerId,
			product.title,
			product.brand,
			product.type,
			product.variantsCount,
			product.status,
			product.source,
			thumbnailUrl,
		);
	}

	private static GetProductThumbnailUrl(productId: number): string
	{
		const url= environment.portalBaseAddress + `/api/v1/media/products/` + productId + `/images/default/thumbnail`;
		return url;
	}

}
