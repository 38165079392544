import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IProductSearchViewModel } from '@shared/models/products';
import { ProductService, IProductFilter } from '@shared/services';
import { Subject, debounce, switchMap, tap, timer } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'product-selector',
	templateUrl: './product-selector.component.html',
	styleUrls: ['./product-selector.component.scss'],
	standalone: false,
})
export class ProductSelectorComponent {
	public products: IProductSearchViewModel[] = [];
	private _searchKeyword$: Subject<string | null> = new Subject();
	public isLoading: boolean = true;

	@Output() addProduct: EventEmitter<IProductSearchViewModel> = new EventEmitter();

	@Input() disabled: boolean = false;

	constructor(private productService: ProductService) {
		this._searchKeyword$
			.pipe(
				tap(_ => {
					this.isLoading = true;
				}),
				debounce(() => timer(300)),
				switchMap(keyword => {
					const filter: IProductFilter = {
						keyword: keyword,
						deleted: false,
					};
					return this.productService.Search(filter);
				}),
				tap(_ => {
					this.isLoading = false;
				}),
				untilDestroyed(this),
			)
			.subscribe(_ => (this.products = _));

		this._searchKeyword$.next(null);
	}

	public onSearch(event: any) {
		this._searchKeyword$.next(event.target.value);
	}

	public onProductSelect(product: IProductSearchViewModel) {
		this.addProduct.emit(product);
	}
}
