import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
	AuthorizedLayoutComponent,
	NavMenuComponent,
	NotFoundComponent,
	PrintersSidebarComponent,
	VendorSelectorPopupComponent,
} from '@layout/components';
import SharedModule from '@shared/shared.module';
import { CoreModule } from '@core/core.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MenuModule } from 'primeng/menu';
import { DrawerModule } from 'primeng/drawer';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';

@NgModule({
	declarations: [
		AuthorizedLayoutComponent,
		NavMenuComponent,
		VendorSelectorPopupComponent,
		NotFoundComponent,
		PrintersSidebarComponent,
	],
	imports: [
		CommonModule,
		DynamicDialogModule,
		SharedModule,
		MenuModule,
		BreadcrumbModule,
		CoreModule,
		DrawerModule,
		RouterLink,
		RouterLinkActive,
		RouterOutlet,
	],
	exports: [AuthorizedLayoutComponent],
})
export default class LayoutModule {}
