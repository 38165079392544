import { Injectable } from '@angular/core';
import { IOrderItemModel } from '@orders/models';
import {
	IProductMediaModel,
	IProductSearchModel,
	IProductSearchViewModel,
	ProductViewModel,
} from '@shared/models/products';

@Injectable()
export abstract class ProductHelper {
	constructor() {}

	public static getProductViewModelWithOrderItem(
		orderItem: IOrderItemModel,
		product: IProductSearchModel,
		media?: IProductMediaModel,
	): ProductViewModel {
		var thumbnailUrl: string | undefined;

		if (media && media.media[0]) {
			thumbnailUrl = media.media[0].thumbnailUrl;
		}

		return new ProductViewModel(
			orderItem.productId,
			product.name,
			product.title,
			product.sku,
			product.itemsPerTag,
			orderItem.quantity,
			orderItem.id,
			orderItem.destinationVerified,
			orderItem.originVerified,
			orderItem.bornDate,
			orderItem.expDate,
			orderItem.sellBy,
			orderItem.lotNumber,
			orderItem.cost,
			orderItem.sn,
			thumbnailUrl,
		);
	}

	public static getProductViewModel(product: IProductSearchViewModel, media?: IProductMediaModel): ProductViewModel {
		var thumbnailUrl: string | undefined;

		if (media && media.media[0]) {
			thumbnailUrl = media.media[0].thumbnailUrl;
		}

		return new ProductViewModel(
			product.productId,
			product.name,
			product.title,
			product.sku,
			product.itemsPerTag,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			thumbnailUrl,
		);
	}
}
